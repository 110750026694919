import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./components/MainTile"),
    },
    {
        path: "/service",
        name: "service",
        component: () => import("./components/ServiceTile"),
    },
    {
        path: "/offer",
        name: "offer",
        component: () => import("./components/OfferTile"),
    },
    {
        path: "/price",
        name: "price",
        component: () => import("./components/PriceTile"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("./components/AboutTile"),
    },
    {
        path: "/links",
        name: "links",
        component: () => import("./components/LinksTile"),
    },
    {
        path: "/admin",
        name: "admin",
        component: () => import("./components/AdminTile"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router