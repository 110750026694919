import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/lara-light-teal/theme.css'
import 'primeicons/primeicons.css'
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

createApp(App)
    .use(PrimeVue)
    .use(router)
    .use(store)
    .use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
    })
    .mount('#app')
