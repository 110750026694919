import { createStore } from 'vuex'

export default createStore({
    state: {
        selectedPage: 'home',
        userId: '',
    },
    mutations: {
        async SET_USER_ID(state, id) {
            state.userId = id
        },
    },
    actions: {
        setUserId(context, id) {
            context.commit('SET_USER_ID', id)
        },
    },
})