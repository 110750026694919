<template>
  <div id="app">
    <div class="container">
      <MainHeader/>
    </div>
    <router-view/>
    <FooterTile />
  </div>
</template>

<script>
import router from '@/router'
import MainHeader from "@/components/MainHeader";
import FooterTile from "@/components/FooterTile";

export default {
  name: 'App',
  components: {
    FooterTile,
    MainHeader,
  },
  data() {
    return {

      router,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Raleway');

#app {
  font-family: 'Raleway', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 16px;
}
</style>
