<template>
  <div class="container-fluid bg-light pt-2">
    <div class="row mt-3">
      <div class="text-secondary">
        <div class="row">
          <div class="col"/>
          <div class="col-lg-3">
            <div class="row mb-2 fw-bold" :class="isMobile ? '' : 'text-start'" >
              <div class="col" style="color: #c50000;">
                Clifton van Halderen bemiddeling
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                BolleNoord
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                Groteweg 9
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                1756 CK  't Zand
              </div>
            </div>
            <div class="row mt-2" :class="isMobile ? '' : 'text-start'" >
              <div class="col">
                <img src="@/assets/phone-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                0224 592300
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/mobile-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                06 22387280
              </div>
            </div>
            <div class="row mb-2" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/email-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                info@bhblelies.com
              </div>
            </div>
            <div class="row mt-2" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                BTW: NL004436680B03
              </div>
            </div>
            <div class="row mb-2" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                KvK: 93369204
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="row mb-2 fw-bold" :class="isMobile ? '' : 'text-start'" @click="openPage('service')">
              <div class="col" style="color: #c50000;">
                Diensten
              </div>
            </div>
            <div class="row mb-2 link" :class="isMobile ? '' : 'text-start'" @click="openPage('service')">
              <div class="col">
                Bemiddeling
              </div>
            </div>
            <div class="row mb-2 link" :class="isMobile ? '' : 'text-start'" @click="openPage('offer')">
              <div class="col">
                Vrijblijvend aanbod
              </div>
            </div>
            <div class="row mb-2 link" :class="isMobile ? '' : 'text-start'" @click="openPage('price')">
              <div class="col">
                Prijsinformatie
              </div>
            </div>
            <div class="row mb-2 link" :class="isMobile ? '' : 'text-start'" @click="openPage('service')">
              <div class="col">
                Taxatierapporten
              </div>
            </div>
            <div class="row mb-3 link" :class="isMobile ? '' : 'text-start'" @click="openPage('service')">
              <div class="col">
                Landverhuur
              </div>
            </div>
            <div class="row mb-4 link" :class="isMobile ? '' : 'text-start'" @click="openPage('admin')">
              <div class="col">
                Login
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="row mb-2 fw-bold" :class="isMobile ? '' : 'text-start'">
              <div class="col" style="color: #c50000;">
                Contact
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                Clifton van Halderen
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
            <div class="col">
              <img src="@/assets/phone-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
              0224 592302
            </div>
          </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/mobile-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                06 22387280
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/email-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                clifton@bhblelies.nl
              </div>
            </div>
            <div class="row mt-3" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                Mirjam de Wit
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/phone-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                0224 592300
              </div>
            </div>
            <div class="row" :class="isMobile ? '' : 'text-start'">
              <div class="col">
                <img src="@/assets/email-16.png" alt="" class="img-fluid" style="width: 16px; filter: grayscale(100);"/>
                mirjam@bhblelies.nl
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col text-secondary opacity-50">
        &#169; Wever Software Consultancy 2024
      </div>

    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "FooterTile",
  data() {
    return {
      router,
    }
  },
  methods: {
    openPage(link) {
      this.router.push('/' + link)
      this.$store.state.selectedPage = link === '' ? 'home' : link
    },
    mobileCheck() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  computed: {
    isMobile() {
      return this.mobileCheck()
    },
  }
}
</script>

<style scoped>
.link:hover {
  font-weight: bold;
}
</style>