<template>
  <div class="row">
    <div class="col-lg-2" :class="isMobile ? 'text-start' : 'text-end'" @click="onDropdownItemClick('')">
      <img src="@/assets/logo.png" class="img-fluid" style="width: 200px;">
    </div>
    <div class="col-lg-10 text-start">
      <div class="row mt-4">
        <div v-show="!isMobile" class="col">
          <div class="ms-3 me-3 d-inline-block" :class="selected === 'home' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('')">
            Home
          </div>
          <div class="menu-item ms-3 me-3 d-inline-block" :class="selected === 'service' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('service')">
            Diensten
          </div>
          <div class="menu-item ms-3 me-3 d-inline-block" :class="selected === 'offer' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('offer')">
            Aanbod
          </div>
          <div class="menu-item ms-3 me-3 d-inline-block" :class="selected === 'price' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('price')">
            Prijsinformatie
          </div>
          <div class="menu-item ms-3 me-3 d-inline-block" :class="selected === 'about' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('about')">
            Over ons
          </div>
          <div class="menu-item ms-3 me-3 d-inline-block" :class="selected === 'links' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('links')">
            Links
          </div>
        </div>
        <div v-show="isMobile" class="col-2 text-end p-0" style="position: fixed; right: 16px; top: 16px;">
            <div class="dropdown">
              <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="@/assets/menu.svg" class="img-fluid mt-0 me-0 opacity-50" style="width: 48px;">
              </button>
              <ul class="dropdown-menu dropdown-menu-end bg-light" style="z-index: 9999;">
                <li>
                  <div class="dropdown-item text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'home' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('')">Home</div>
                  </div>
                </li>
                <li>
                  <a class="dropdown-item me-3 text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'service' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('service')">Diensten</div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item me-3 text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'offer' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('offer')">Aanbod</div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item me-3 text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'price' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('price')">Prijsinformatie</div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item me-3 text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'about' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('about')">Over ons</div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item me-3 text-secondary">
                    <!--img src="@/assets/green-money-bag-16.png" class="img-fluid me-2"-->
                    <div :class="selected === 'links' ? 'menu-item-selected' : 'menu-item'" @click="onDropdownItemClick('links')">Links</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
      </div>
      <div class="watermarkk">
        <img src="@/assets/lily.png" alt="lily" class="img-fluid" style="position: absolute; z-index: -1; top: 240px; right: 10%; width: 600px; opacity: 8%;"/>
      </div>
    </div>
  </div>

</template>

<script>
import router from '@/router'

export default {
  name: "MainHeader",
  components: {

  },
  data() {
    return {
        //selected: 'home',
        router,
      }
  },
  methods: {
    mobileCheck() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    onDropdownItemClick(link) {
      this.router.push('/' + link)
      this.$store.state.selectedPage = link === '' ? 'home' : link
    },
  },
  computed: {
    isMobile() {
      return this.mobileCheck()
    },
    selected() {
      return this.$store.state.selectedPage
    }

  }
}
</script>

<style scoped>
.menu-item {
  padding: 4px 8px 4px 8px;
}
.menu-item:hover {
  font-weight: bold;
  color: #c50000;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 4px 8px 4px 8px;
}
.menu-item-selected {
  font-weight: bold;
  color: #c50000;
  padding: 4px 8px 4px 8px;
}
</style>